import { getPathFiltersParameters, getQueryFiltersParameters } from 'utils/request';
import { stringify } from 'qs';
import { goToURL } from 'utils/location';
import { getUrlWithEndpointParameters } from 'utils';

/**
 * Helper for take path params and made a string with them
 * @param { object } data
 * @return { string }
 */
const generatePathString = data => {
	if (!data) return '';

	const parsePath = Object.keys(data)
		.map(value => `{${value}}`)
		.join('/');

	return `/${parsePath}`;
};

/**
 * Helper for make an url from a schema source
 * @param { object } source
 * @param { string } id
 * @return { string }
 */
export const createURLFromSource = async (source, id, endpointParameters) => {
	const pathQueries = getPathFiltersParameters(endpointParameters, { id });
	const pathsString = generatePathString(pathQueries);

	const { service, namespace, method } = source;
	const baseUrlToRedirect = `/${service}/${namespace}/${method}`;

	return getUrlWithEndpointParameters(`${baseUrlToRedirect}${pathsString}`, pathQueries);
};

/**
 * Helper for add an filters to URL filters with endpointParameters
 * @param { object } endpointParameters
 * @param { string } sourcePath
 * @return { string }
 */
export const addQueryFiltersToURl = (endpointParameters, sourcePath, data = {}) => {
	let path = sourcePath;
	const queries = getQueryFiltersParameters(endpointParameters, data);

	if (queries) {
		const linkHasQuery = sourcePath.includes('?');
		path = `${sourcePath}${linkHasQuery ? '&' : '?'}${stringify(queries)}`;
	}

	return path;
};

/**
 * Helper for make an URL with filters and paths from endpointParameters and source
 * @param { array } endpointParameters
 * @param { object } source
 * @param { object } id
 * @return { string }
 */
export const createURLWithEndPointParametersAndSource = async (endpointParameters, source, id) => {
	if (!source) return '';
	const sourcePath = await createURLFromSource(source, id, endpointParameters);

	if (!endpointParameters) return sourcePath;

	const decodeURL = decodeURIComponent(addQueryFiltersToURl(endpointParameters, sourcePath));
	return decodeURL;
};

/**
 * Make an Url and go to them
 * @param { object } redirectObject
 * @param { string } id
 */
export const handleRedirect = async (redirectObject, id) => {
	const { source, endpointParameters } = redirectObject;
	const redirectURL = await createURLWithEndPointParametersAndSource(
		endpointParameters,
		source,
		id
	);
	goToURL(redirectURL);
};
