import isEmail from 'validator/lib/isEmail';
import { get as lodashGet } from 'lodash';
import moment from 'moment';
import { isValid } from 'date-fns';

export const required = value =>
	(value && typeof value === 'object'
	? Object.keys(value).length || isValid(value)
	: value)
		? undefined
		: 'views.validation.isRequired';

export const email = value =>
	value && !isEmail(value) ? 'views.validation.shouldBeAnEmail' : undefined;

export const minLength = min => value =>
	value && value.length < min ? 'views.validation.shouldHaveAMinimumLength' : undefined;

export const maxLength = max => value =>
	value && value.length > max ? 'views.validation.shouldNotExceedTheMaximumLength' : undefined;

export const minValue = min => value =>
	Number(value) < Number(min) ? 'views.validation.mustExceedMinimumValue' : undefined;

export const maxValue = max => value =>
	Number(value) > Number(max) ? 'views.validation.shouldNotExceedTheMaximumValue' : undefined;

export const numeric = value =>
	Number.isNaN(Number(value)) ? 'views.validation.shouldBeANumber' : undefined;

export const integer = value =>
	value && !Number.isInteger(Number(value))
		? 'views.validation.numberShouldBeAnInteger'
		: undefined;

export const positive = value =>
	Math.sign(value) <= 0 ? 'views.validation.numberShouldBePositive' : undefined;

export const literal = (match, referenceValueType) => (value, allValues) => {
	const referenceValue = referenceValueType === 'dynamic' ? lodashGet(allValues, match) : match;
	return value !== referenceValue ? `views.validation.valueMustMatch ${match}` : undefined;
};

export const validTimeRange = value => {
	const { from, to } = value;
	return moment(from).isAfter(to) ? 'views.validation.invalidTimeRange' : undefined;
};

export const validWeighablePurchasedQuantity = product => value =>
	product && Number.isInteger(value / product.sellingUnitMultiplier)
		? undefined
		: 'views.validation.mustBeSellingUnitMultiplierMultiple';
export const url = value => {
	const urlRegex = /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i; //eslint-disable-line

	const isValidURL = urlRegex.test(value);

	return !value || isValidURL ? undefined : `views.validation.isNotUrl`;
};

export const validateWeighableMesurementUnit = product => value => {
	const numericValue = Number(value);
	return product.sellingMeasurementUnit === 'un' && !Number.isInteger(numericValue)
		? 'views.validation.numberShouldBeAnInteger'
		: undefined;
};
